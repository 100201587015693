<template>
    <PlainLayout>
        <template #header>
            <img src="@/assets/layout/images/icon-404.png" />
        </template>
        <template #content>
            <div class="grid">
                <div class="col-12 py-2">
                    <h1 class="font-bold">PAGINA NO ENCONTRADA</h1>
                    <h2>La solicitud no es válida</h2>
                </div>

                <div class="col-12 text-center">
                <router-link class="font-bold"  :class="'p-button'"  :to="{ name: 'Login' }">Ir a inicio</router-link>
                </div>
            </div>
        </template>
    </PlainLayout>
</template>

<script>
import PlainLayout from "@/layout/PlainLayout.vue";
export default {
  components: { PlainLayout },
};
</script>